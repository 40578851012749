import React from "react";

import { STRIPE_PUBLISHABLE_API_KEY } from "util/env";

import WestIcon from "@mui/icons-material/West";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Stepper } from "components/molecules/Stepper";
import { CheckoutForm } from "components/organisms/CheckoutForm";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY);

type Props = {
  goBack: () => void;
  clientSecret: undefined | string;
};

export const Presenter: React.FC<Props> = (props) => {
  const { goBack, clientSecret } = props;
  const appearance = {
    theme: "stripe" as "stripe",
  };

  const options = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    clientSecret: clientSecret,
    appearance: appearance,
  };

  return (
    <Box>
      <IconButton onClick={goBack} sx={{ color: "func.100", width: 3 }}>
        <WestIcon />
      </IconButton>
      <Box padding={2}>
        <Stepper activeStep={2} />
      </Box>
      <Stack spacing={3} alignItems="center">
        <Typography variant="h2">お支払い方法登録</Typography>
        <Typography variant="bold14">
          クレジットカードを入力してください
        </Typography>
        <Elements stripe={stripePromise} options={options}>
          {clientSecret && (
            <Box padding={2} width="100%">
              <CheckoutForm clientSecret={clientSecret} />
            </Box>
          )}
        </Elements>
      </Stack>
    </Box>
  );
};
