import { useRef, useState } from "react";

import { INSURANCE_CARD_BUCKET } from "util/env";
import { fetchUploadSignedUrl } from "util/fetchSignedUrl";

import { PutPatientInsuranceCardsBodiesIndex, usePutPatientInsuranceCardsHook } from "api-client";
import imageCompression from "browser-image-compression";

const insuranceCardBucket = INSURANCE_CARD_BUCKET;

export const useUploadInsuranceCards = () => {
  const putPatientInsuranceCards = usePutPatientInsuranceCardsHook();
  const [uploadedFile, setUploadedFile] = useState<{
    insuranceCardPhoto0: {
      compressedFile: File | null;
      preview: string | ArrayBuffer | null;
    };
    insuranceCardPhoto1: {
      compressedFile: File | null;
      preview: string | ArrayBuffer | null;
    };
  }>({
    insuranceCardPhoto0: {
      compressedFile: null,
      preview: null,
    },
    insuranceCardPhoto1: {
      compressedFile: null,
      preview: null,
    }
  });

  const handleImageChange = async (
    file: File,
    index: number
  ) => {
    // 画像の圧縮
    const compressedFile = await imageCompression(file, {
      initialQuality: 0.7, // JPEGの品質を調整する値
    });
    displayFile(compressedFile).then((imageData) => {
      setUploadedFile((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        if (index === 0) {
          return {
            ...updatedFiles,
            insuranceCardPhoto0: { compressedFile, preview: imageData },
          };
        }
        return {
          ...updatedFiles,
          insuranceCardPhoto1: { compressedFile, preview: imageData },
        };
      });
    });
  };

  // SetされたFileの参照情報を確認するための ref_Data: Reference_Data
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const subFileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadImage = async (file: File, index: number, patientId: string) => {
    const url = await fetchUploadSignedUrl(
      insuranceCardBucket,
      patientId,
      `insuranceCardPhoto${index}`,
      file.type
    );

    if (!url) {
      throw new Error("アップロード用のURLを取得できませんでした。");
    }

    try {
      const res = await fetch(url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!res.ok) {
        throw new Error(`アップロードに失敗しました。: ${res.status}`);
      }

      // FIXME: S3にアップロードしたイベント通知でLambdaを呼び出せるよう改修した場合、
      //        このAPI呼び出しの処理を削除する
      await putPatientInsuranceCards({
        id: patientId,
        index:
          index === 0
            ? PutPatientInsuranceCardsBodiesIndex.NUMBER_0
            : PutPatientInsuranceCardsBodiesIndex.NUMBER_1,
      });
    } catch (e) {
      throw new Error('アップロードに失敗しました');
    }
  };

const displayFile = (file: File) => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!file) {
      resolve(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result;
      console.log(imageData); // 画像データが表示されるまで待ってからログ
      resolve(imageData);
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };
    reader.readAsDataURL(file);
  });
};

  return {
    handleImageChange,
    imageIconClick: () => fileInputRef.current?.click(),
    subImageIconClick: () => subFileInputRef.current?.click(),
    disableSubmitButton:
      uploadedFile.insuranceCardPhoto0 === null ||
      uploadedFile.insuranceCardPhoto1 === null,
    fileInputRef,
    subFileInputRef,
    uploadImage,
    uploadedFile,
  };
};
