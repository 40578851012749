import React, { createContext, useCallback, useState } from 'react';

import useHandleTrackPublicationFailed from 'components/organisms/OnlineConsultation/useHandleTrackPublicationFailed';
import { useRoom } from 'components/organisms/OnlineConsultation/useRoom';
import { SelectedParticipantProvider } from 'components/organisms/OnlineConsultation/useSelectedParticipant';
import { toast } from 'react-toastify';
import { createLocalTracks, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack, Room } from 'twilio-video';

export interface IVideoContext {
  room: Room | null;
  localVideoTrack: LocalVideoTrack | undefined;
  remoteVideoTrack: RemoteVideoTrack | null;
  remoteAudioTrack: RemoteAudioTrack | null;
  isConnecting: boolean;
  connectVideo: (token: string, roomName: string) => Promise<void>;
  hasConnected: boolean;
}

export const VideoContext = createContext<IVideoContext>(null!);

export const VideoProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [localVideoTrack, setLocalVideoTrack] =
    useState<LocalVideoTrack>();
  const { room, isConnecting, hasConnected, connect, remoteVideoTrack, remoteAudioTrack } =
    useRoom();

  const connectVideo = useCallback(async (token: string, roomName: string) => {
    try {
      const tracks = await createLocalTracks({
        audio: true,
        video: { facingMode: "user" }
      });
      const newVideoTrack = tracks.find((track) => track.kind === "video") as LocalVideoTrack;
      setLocalVideoTrack(newVideoTrack);
      if (!roomName) return;

      connect(token, roomName, tracks);
    } catch (e: any) {
      toast.error("カメラが許可されていません");
      return;
    }
  }, [connect]);

  useHandleTrackPublicationFailed(room);

  return (
    <VideoContext.Provider
      value={{
        room,
        isConnecting,
        localVideoTrack,
        remoteVideoTrack,
        remoteAudioTrack,
        connectVideo,
        hasConnected,
      }}
    >
      <SelectedParticipantProvider room={room}>{props.children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
