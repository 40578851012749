import { forwardRef } from "react";

import {
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";

import { ErrorTypography } from "../Typography";

export type TextInputProps = {
  label: string;
  type: string;
  required: boolean;
  error: boolean;
  errorText?: string;
  defaultValue?: string;
};

export const TextFieldWithBirthDay = forwardRef<
  HTMLInputElement,
  TextInputProps
>(
  (
    {
      label,
      type,
      required,
      error,
      errorText,
      defaultValue,
      ...field
    },
    ref
  ) => {
    const todayString = dayjs(new Date()).format("YYYY-MM-DD");

    return (
      <FormControl variant="filled">
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="bold14">{label}</Typography>
            {required && <Box color="alert.100">*</Box>}
          </Stack>

          <OutlinedInput
            type={type}
            ref={ref}
            inputProps={{
              max: todayString,
              defaultValue: defaultValue || "",
              style: {
                padding: "13px 16px",
              },
            }}
            {...field}
          />
          {error && errorText && <ErrorTypography errorText={errorText} />}
        </Stack>
      </FormControl>
    );
  }
);
