import dayjs from "dayjs";
import "dayjs/locale/ja";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale("ja"); // use locale
dayjs.updateLocale("ja", { weekStart: 1 });

export const convertStringDate = (utcUnixTimestamp: string) => {
  return dayjs(utcUnixTimestamp).format("YYYY年 M月D日 HH:mm");
};

export default dayjs;
