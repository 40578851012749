import React, {useEffect, useState} from "react";

import {
  fetchDownloadSignedUrl,
  FILE_SIZE_ERROR_MESSAGE,
  MAX_FILE_SIZE,
} from "util/fetchSignedUrl";

import WestIcon from "@mui/icons-material/West";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {CardField} from "components/atoms/CardField";
import {Stepper} from "components/molecules/Stepper";
import {useLocalStorage} from "hooks/useLocalStorage";
import {useUploadInsuranceCards} from "hooks/useUploadInsuranceCards";
import {toast} from "react-toastify";

import {INSURANCE_CARD_BUCKET} from "../../../util/env";

type Props = {
  goNext: () => void;
  goBack: () => void;
  isCardRenewalMonth: boolean;
};

export const Presenter: React.FC<Props> = (props) => {
  const {goNext, goBack, isCardRenewalMonth} = props;
  const {
    handleImageChange,
    imageIconClick,
    subImageIconClick,
    fileInputRef,
    subFileInputRef,
    uploadImage,
    uploadedFile,
  } = useUploadInsuranceCards();
  const [loading, setLoading] = useState(false);
  const [insuranceCardPhoto0, setInsuranceCardPhoto0]
    = useState<string | undefined>(undefined);
  const [insuranceCardPhoto1, setInsuranceCardPhoto1]
    = useState<string | undefined>(undefined);
  const [value] = useLocalStorage("patientId");
  const patientId = value || "";

  const handleButtonClick = async () => {
    if (!((uploadedFile.insuranceCardPhoto0.compressedFile || insuranceCardPhoto0) ||
      !(uploadedFile.insuranceCardPhoto1.compressedFile || insuranceCardPhoto1))) {
      toast.error("保険証の表面と裏面の両方をアップロードしてください");
      return;
    }
    setLoading(true);
    if (!uploadedFile.insuranceCardPhoto0.compressedFile && !uploadedFile.insuranceCardPhoto1.compressedFile && insuranceCardPhoto0 && insuranceCardPhoto1) {
      goNext();
    }
    if (uploadedFile.insuranceCardPhoto0.compressedFile && !uploadedFile.insuranceCardPhoto1.compressedFile) {
      await uploadImage(
        uploadedFile.insuranceCardPhoto0.compressedFile,
        0,
        patientId
      )
        .then(() => {
          goNext();
        })
        .catch((e) => {
          toast.error(`${e}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (uploadedFile.insuranceCardPhoto1.compressedFile && !uploadedFile.insuranceCardPhoto0.compressedFile) {
      await uploadImage(
        uploadedFile.insuranceCardPhoto1.compressedFile,
        1,
        patientId
      )
        .then(() => {
          goNext();
        })
        .catch((e) => {
          toast.error(`${e}`);
        })
        .finally(() => {
            setLoading(false);
          }
        );
    }
    if (uploadedFile.insuranceCardPhoto0.compressedFile && uploadedFile.insuranceCardPhoto1.compressedFile) {
      await Promise.all([
        uploadImage(
          uploadedFile.insuranceCardPhoto0.compressedFile,
          0,
          patientId
        ),
        uploadImage(
          uploadedFile.insuranceCardPhoto1.compressedFile,
          1,
          patientId
        ),
      ])
        .then(() => {
          goNext();
        })
        .catch((e) => {
          toast.error(`${e}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpload = async (file: File, fileName: string, index: number) => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error(FILE_SIZE_ERROR_MESSAGE);
      return;
    }
    handleImageChange(file, index);
  };

  useEffect(() => {
    const updateSignedUrls = async () => {
      const photoNames = ["insuranceCardPhoto0", "insuranceCardPhoto1"];
      const signedUrls = await Promise.all(
        photoNames.map(async (fileName) => {
          const url = await fetchDownloadSignedUrl(INSURANCE_CARD_BUCKET, patientId, fileName);
          if (url) {
            const response = await fetch(url);
            return response.ok ? url : undefined;
          } else {
            return undefined;
          }
        })
      );
      setInsuranceCardPhoto0(signedUrls[0]);
      setInsuranceCardPhoto1(signedUrls[1]);
    };
    updateSignedUrls();
  }, [patientId]);


  return (
    <Box>
      <IconButton onClick={goBack} sx={{color: "func.100", width: 3}}>
        <WestIcon/>
      </IconButton>
      <Box padding={2}>
        <Stepper activeStep={1}/>
      </Box>

      <Box paddingBottom={3}>
        <Typography variant="h2" textAlign="center">
          保険証登録
        </Typography>
      </Box>
      <Stack spacing={2} paddingBottom={10}>
        <CardField
          label="表面"
          index={0}
          handleUpload={handleUpload}
          imageIconClick={imageIconClick}
          fileInputRef={fileInputRef}
          isError={isCardRenewalMonth}
          previewImage={uploadedFile.insuranceCardPhoto0.preview}
          insuranceCardPhoto={insuranceCardPhoto0}
        />
        <CardField
          label="裏面"
          index={1}
          handleUpload={handleUpload}
          imageIconClick={subImageIconClick}
          fileInputRef={subFileInputRef}
          isError={isCardRenewalMonth}
          previewImage={uploadedFile.insuranceCardPhoto1.preview}
          insuranceCardPhoto={insuranceCardPhoto1}
        />
      </Stack>
      <Box width={"90vw"} position={"fixed"} sx={{bottom: 16}}>
        <Button
          variant="onboarding"
          color="primary"
          type="submit"
          sx={{width: "100%"}}
          disabled={
            !(
              (uploadedFile.insuranceCardPhoto0.compressedFile || insuranceCardPhoto0) &&
              (uploadedFile.insuranceCardPhoto1.compressedFile || insuranceCardPhoto1)
            )
          }
          startIcon={loading ? <CircularProgress size={20}/> : null}
          onClick={handleButtonClick}
        >
          次へ
        </Button>
      </Box>
    </Box>
  );
};
