import { useNavigate } from "react-router-dom";

export const useNavigates = () => {
  const navigate = useNavigate();

  const navigateConsultationDetail = (id: string) => {
    navigate(`/consultations/${id}`);
  };

  const navigateConsultations = (step: number | string) => {
    navigate(`/consultations?step=${step}`);
  };

  const navigateReConsultation = (id: string, step: number | string) => {
    navigate(`/reConsultations/${id}?step=${step}`);
  };

  const navigateOnlineConsultation = (id?: string) => {
    navigate(`/onlineConsultation/${id}`);
  };

  const navigateHome = (done?: string) => {
    navigate(`${done ? done : "/"}`);
  };

  const navigateUploadInsuranceCards = () => {
    navigate("/uploadInsuranceCards");
  };

  const navigateAddPaymentMethod = () => {
    navigate("/addPaymentMethod");
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const navigateMe = (type?: string) => {
    navigate(`/me?type=${type}`);
  };

  const navigateSignUpFinish = () => {
    navigate("/signUpFinish");
  };

  const navigateUserRegister = () => {
    navigate("/userRegister");
  };

  const navigateConfirmSignUp = (email: string, password: string) => {
    navigate("/confirmSignUp", { state: { email: email, password: password } });
  };

  const navigateUsageHistory = () => {
    navigate("/usageHistory");
  };

  const navigateLogin = () => {
    navigate("/");
  };

  const navigateReEntryRoom = (id: string) => {
    navigate(`/reEntryRoom/${id}`);
  };

  //FIXME: パス名が決定次第修正
  const navigateConsultationsNew = (step: number | string) => {
    navigate(`/consultationsNew?step=${step}`);
  };

  return {
    navigateHome,
    navigateBack,
    navigateConsultationDetail,
    navigateConsultations,
    navigateReConsultation,
    navigateOnlineConsultation,
    navigateUploadInsuranceCards,
    navigateAddPaymentMethod,
    navigateMe,
    navigateSignUpFinish,
    navigateUserRegister,
    navigateConfirmSignUp,
    navigateLogin,
    navigateUsageHistory,
    navigateReEntryRoom,
    navigateConsultationsNew,
  };
};
