export const GENDER = [
  {
    label: "男性",
    value: "MALE",
  },
  {
    label: "女性",
    value: "FEMALE",
  },
];
