import { useSignedUrlHook } from "api-client";

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes
export const FILE_SIZE_ERROR_MESSAGE = "ファイルサイズは20MB以下にしてください";

const getSignedUrl = useSignedUrlHook();

export const fetchDownloadSignedUrl = async (
  bucketName: string,
  path: string,
  fileName: string
): Promise<string | undefined> => {
  try {
    const response = await getSignedUrl({
      bucketName: bucketName,
      bucketSubDirectoryPath: path,
      fileName: fileName,
      type: "get",
    });
    return response.result;
  } catch (error) {
    console.error(`署名付きURLの取得に失敗しました: ${fileName}`, error);
    return undefined;
  }
};

export const fetchUploadSignedUrl = async (
  bucketName: string,
  path: string,
  fileName: string,
  fileType: string
): Promise<string | undefined> => {
  try {
    const response = await getSignedUrl({
      bucketName: bucketName,
      bucketSubDirectoryPath: path,
      fileName: fileName,
      type: "put",
      fileType: fileType,
    });
    return response.result;
  } catch (error) {
    console.error(`署名付きURLの取得に失敗しました: ${fileName}`, error);
    return undefined;
  }
};
