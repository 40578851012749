//import { useEffect, useState } from 'react';

//import { useGetPatientHook } from 'api-client';
//import { userState } from 'atoms/userState';
import AddPaymentMethodPage from "pages/AddPaymentMethodPage";
import UploadInsuranceCardsPage from "pages/UploadInsuranceCardsPage";
import UserRegisterPage from "pages/UserRegisterPage";
// import { AuthInfoContext } from 'providers/AuthContextProvider';
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { me } from "selectors/user";

import { Session } from "./Session";

const IsLogined = (props: any) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const idToken = localStorage?.getItem("idToken");
  //const [patient, setPatient] = useState<any>(null);
  /*const refreshUserInfo = useRecoilCallback(
    ({ set }) =>
      async () => {
        const patient = useGetPatientHook();
        const me = patient();
        set(userState, await me);
      },
    [me]
  );*/
  /*const getPatient = async () => {
    const patient = useGetPatientHook();
    const me = await patient();
    setPatient(me);
  }*/

  /*useEffect(() => {
    getPatient();
    // eslint-disable-next-line
  }, []);*/

  const searchParams = new URLSearchParams(location.search);
  const doctorId = searchParams.get('doctorId');
  if (doctorId) {
    localStorage.setItem("doctorId", doctorId);
  }

  if (idToken) {
    const patient = useRecoilValue(me);
    if (
      patient?.finishedInitRegister ||
      localStorage.getItem("finishedInitRegister") === "true"
    ) {
      return (
        <>
          <Session />
          {props.children}
        </>
      );
    } else {
      if (currentPath === "/userRegister") {
        return (
          <>
            <Session />
            <UserRegisterPage />
          </>
        );
      } else if (currentPath === "/uploadInsuranceCards") {
        return (
          <>
            <Session />
            <UploadInsuranceCardsPage />
          </>
        );
      } else if (currentPath === "/addPaymentMethod") {
        return (
          <>
            <Session />
            <AddPaymentMethodPage />
          </>
        );
      } else {
        return (
          <>
            <Session />
            <UserRegisterPage />
          </>
        );
      }
    }
  } else {
    const currentUrl = encodeURIComponent(window.location.pathname + window.location.search);
    return <Navigate to={`/login?done=${currentUrl}`} />;
  }
};
export default IsLogined;
