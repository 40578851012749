import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../svgs/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../svgs/arrowRight.svg";
import { ReactComponent as Avatar } from "../svgs/avatar.svg";
import { ReactComponent as Calender } from "../svgs/calendar.svg";
import { ReactComponent as Calender2 } from "../svgs/calendar2.svg";
import { ReactComponent as CalenderDone } from "../svgs/calenderDone.svg";
import { ReactComponent as Call } from "../svgs/call.svg";
import { ReactComponent as Camera } from "../svgs/camera.svg";
import { ReactComponent as Card } from "../svgs/card.svg";
import { ReactComponent as Carte } from "../svgs/carte.svg";
import { ReactComponent as ChangeCamera } from "../svgs/changeCamera.svg";
import { ReactComponent as Circle } from "../svgs/circle.svg";
import { ReactComponent as CircleClose } from "../svgs/circleClose.svg";
import { ReactComponent as Close } from "../svgs/close.svg";
import { ReactComponent as Eye } from "../svgs/eye.svg";
import { ReactComponent as HangUp } from "../svgs/hangUp.svg";
import { ReactComponent as Hospital } from "../svgs/hospital.svg";
import { ReactComponent as Medicine } from "../svgs/medicine.svg";
import { ReactComponent as Monochrome } from "../svgs/monochrome.svg";
import { ReactComponent as OutlineAvatar } from "../svgs/outlineAvatar.svg";
import { ReactComponent as OutlineBell } from "../svgs/outlineBell.svg";
import { ReactComponent as OutlineCalender } from "../svgs/outlineCalender.svg";
import { ReactComponent as OutlineLock } from "../svgs/outlineLock.svg";
import { ReactComponent as OutlineLogout } from "../svgs/outlineLogout.svg";
import { ReactComponent as OutlineMail } from "../svgs/outlineMail.svg";
import { ReactComponent as OutlineMedicine } from "../svgs/outlineMedicine.svg";
import { ReactComponent as OutlineMonochrome } from "../svgs/outlineMonochrome.svg";
import { ReactComponent as OutlineUpload } from "../svgs/outlineUpload.svg";
import { ReactComponent as OutlineWarning } from "../svgs/outlineWarning.svg";
import { ReactComponent as Prescription } from "../svgs/prescription.svg";
import { ReactComponent as RecordOff } from "../svgs/recordOff.svg";
import { ReactComponent as RecordOn } from "../svgs/recordOn.svg";
import { ReactComponent as SmallArrowRight } from "../svgs/smallArrowRight.svg";
import { ReactComponent as Yen } from "../svgs/yen.svg";


const iconList: { [name: string]: any } = {
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  avatar: <Avatar />,
  calendar: <Calender />,
  calendar2: <Calender2 />,
  calenderDone: <CalenderDone />,
  call: <Call />,
  camera: <Camera />,
  card: <Card />,
  carte: <Carte />,
  changeCamera: <ChangeCamera />,
  circle: <Circle />,
  circleClose: <CircleClose />,
  close: <Close />,
  eye: <Eye />,
  hangUp: <HangUp />,
  hospital: <Hospital />,
  medicine: <Medicine />,
  monochrome: <Monochrome />,
  outlineAvatar: <OutlineAvatar />,
  outlineBell: <OutlineBell />,
  outlineCalender: <OutlineCalender />,
  outlineLock: <OutlineLock />,
  outlineLogout: <OutlineLogout />,
  outlineMail: <OutlineMail />,
  outlineMedicine: <OutlineMedicine />,
  outlineMonochrome: <OutlineMonochrome />,
  outlineUpload: <OutlineUpload />,
  outlineWarning: <OutlineWarning />,
  prescription: <Prescription />,
  recordOn: <RecordOn />,
  recordOff: <RecordOff />,
  smallArrowRight: <SmallArrowRight />,
  yen: <Yen />,
};

export type IconNameType =
  | "arrowLeft"
  | "arrowRight"
  | "avatar"
  | "calendar"
  | "calendar2"
  | "calenderDone"
  | "call"
  | "camera"
  | "card"
  | "carte"
  | "changeCamera"
  | "circle"
  | "circleClose"
  | "close"
  | "eye"
  | "hangUp"
  | "hospital"
  | "medicine"
  | "monochrome"
  | "outlineAvatar"
  | "outlineBell"
  | "outlineCalender"
  | "outlineLock"
  | "outlineLogout"
  | "outlineMail"
  | "outlineMedicine"
  | "outlineMonochrome"
  | "outlineUpload"
  | "outlineWarning"
  | "prescription"
  | "recordOff"
  | "recordOn"
  | "smallArrowRight"
  | "yen";

export interface Props extends React.HTMLProps<SvgIconProps> {
  iconName: IconNameType;
  color?: string;
  size: number;
}

export const Icon: React.FC<Props> = (props) => {
  const { iconName, color, size } = props;

  return (
    <SvgIcon sx={{ color: color, fontSize: size }}>
      {iconList[iconName]}
    </SvgIcon>
  );
};
