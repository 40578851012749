import { PatientModelDTO } from "api-client";
import dayjs from "lib/dayjs";

export const isCardRenewalMonth = (patient: PatientModelDTO): boolean => {
  const thisMonth = dayjs().month() + 1
  const insuranceCardsupdateAt: any = new Date(patient?.insuranceCardUpdatedAt);
  const insuranceCardsupdateAtTime = insuranceCardsupdateAt.getTime();

  let nowInsuaranceCardsMonth: number = dayjs(
    patient?.insuranceCardUpdatedAt
  ).month();
  let insuaranceCardsMonth: number =
    dayjs(patient?.insuranceCardUpdatedAt).month() + 2;

  if (insuaranceCardsMonth >= 13) {
    insuaranceCardsMonth = 1;
  }
  console.log(insuaranceCardsMonth);

  // 対象の月の初日と翌月の初日を取得
  const nowMonthStartDate = new Date(
    new Date().getFullYear(),
    nowInsuaranceCardsMonth,
    1
  );
  const nowMonthEndDate = new Date(
    new Date().getFullYear(),
    nowInsuaranceCardsMonth + 1,
    1
  );

  // 1日前までの時刻を設定して月末にする
  nowMonthEndDate.setTime(nowMonthEndDate.getTime() - 1);

  // 初日から月末までの期間をミリ秒で取得
  const cardRenewalMonthIsThisMonth =
    !!patient.insuranceCardUpdatedAt &&
    // dayjs().month()は0から開始のインデックスを返す。
    // そのため、例えば10月であれば9が返却される。
    // よって比較時には+2する必要あり
    (insuaranceCardsMonth === thisMonth ||
      !(
        nowMonthStartDate.getTime() <= insuranceCardsupdateAtTime &&
        insuranceCardsupdateAtTime <= nowMonthEndDate.getTime()
      ));

  if (cardRenewalMonthIsThisMonth === undefined) return false;

  return cardRenewalMonthIsThisMonth;
};
