import { ENV } from "./env";

// ルール:
// フィーチャーフラグの使用箇所を明確にするため、機能名やそれに準ずる命名をフラグ名として使用する

const local = "local";
const developmentNew = "development-new";
const stagingNew = "staging-new";
// const productionNew = "production-new";

export const isNotificationEnabled = () => {
  return [developmentNew, stagingNew].includes(ENV);
};

export const isNewOnlineConsultationEnabled = () => {
  return [local, developmentNew].includes(ENV);
};

export const isWakeLockEnabled = () => {
  return [local, developmentNew, stagingNew].includes(ENV);
};

// https://www.notion.so/119e6cf8d068806a8f31f5bd745be08c?pvs=4 v1.15にて解消
export const isDisplayValidationPrefectureEnabled = () => {
  return [local, developmentNew, stagingNew].includes(ENV);
}
