import React from "react";

import { isCardRenewalMonth } from "util/isCardRenewalMonth";

import { useGetPatient } from "api-client";
import { useNavigates } from "hooks/useNavigates";

import { Presenter } from "./Presenter";

export const UploadInsuranceCard: React.FC = () => {
  const { data } = useGetPatient();
  const { navigateAddPaymentMethod, navigateBack } = useNavigates();

  if (!data) {
    return null;
  }

  return (
    <Presenter
      goNext={navigateAddPaymentMethod}
      goBack={navigateBack}
      isCardRenewalMonth={isCardRenewalMonth(data)}
    />
  );
};
