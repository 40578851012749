import React from "react";

import { UploadInsuranceCard } from "components/organisms/UploadInsuranceCard";
import AuthenticationLayout from "components/template/AuthenticationLayout";

const UploadInsuranceCardsPage: React.FC = () => {
  return (
    <AuthenticationLayout>
      <UploadInsuranceCard />
    </AuthenticationLayout>
  );
};

export default UploadInsuranceCardsPage;
