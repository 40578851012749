import { lazy, Suspense } from "react";

import { Loading } from "components/atoms/Loading";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const ReEntryRoomPage = lazy(() => import("./ReEntryRoomPage"));

import IsLogined from "../util/IsLogined";

import "react-toastify/dist/ReactToastify.css";

const SignUpPage = lazy(() => import("./SignUpPage"));
const PrivacyPolicyPage = lazy(() => import("./PrivacyPolicyPage"));
const ConfirmSignUpPage = lazy(() => import("./ConfirmSignUpPage"));
const UserRegisterPage = lazy(() => import("./UserRegisterPage"));
const UploadInsuranceCardsPage = lazy(
  () => import("./UploadInsuranceCardsPage")
);
const AddPaymentMethodPage = lazy(() => import("./AddPaymentMethodPage"));
const SignUpFinishPage = lazy(() => import("./SignUpFinishPage"));
const HomePage = lazy(() => import("./HomePage"));
const UsageHistoryPage = lazy(() => import("./UsageHistoryPage"));
const MyPage = lazy(() => import("./MyPage"));
const LoginPage = lazy(() => import("./LoginPage"));
const ResetPasswordPage = lazy(() => import("./ResetPasswordPage"));
const ReservationDetailPage = lazy(() => import("./ReservationDetailPage"));
const OnlineConsultationPage = lazy(() => import("./OnlineConsultationPage"));
const OnlineConsultationFinishedPage = lazy(
  () => import("./OnlineConsultationFinishedPage")
);
const ConsultationPage = lazy(() => import("./ConsultationPage"));
const ReConsultationsPage = lazy(() => import("./ReConsultationsPage"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const AuthCallbackPage = lazy(() => import("./AuthCallbackPage"));
const ConsultationNewPage = lazy(() => import("./ConsultationNewPage"));

const pages = [
  {
    path: "/",
    component: (
      <>
        <IsLogined>
          <Suspense fallback={<Loading isLoading />}>
            <HomePage />
          </Suspense>
        </IsLogined>
      </>
    ),
  },
  {
    path: "/signUp",
    component: <SignUpPage />,
  },
  {
    path: "/login",
    component: <LoginPage />,
  },
  {
    path: "/resetPassword",
    component: <ResetPasswordPage />,
  },
  {
    path: "/confirmSignUp",
    component: <ConfirmSignUpPage />,
  },
  {
    path: "/userRegister",
    component: (
      <IsLogined>
        <UserRegisterPage />
      </IsLogined>
    ),
  },
  {
    path: "/uploadInsuranceCards",
    component: (
      <IsLogined>
        <UploadInsuranceCardsPage />
      </IsLogined>
    ),
  },
  {
    path: "/addPaymentMethod",
    component: (
      <IsLogined>
        <AddPaymentMethodPage />
      </IsLogined>
    ),
  },
  {
    path: "/signUpFinish",
    component: (
      <IsLogined>
        <SignUpFinishPage />
      </IsLogined>
    ),
  },
  {
    path: "/consultations/:id",
    component: (
      <IsLogined>
        <ReservationDetailPage />
      </IsLogined>
    ),
  },
  {
    path: "/usageHistory",
    component: (
      <IsLogined>
        <UsageHistoryPage />
      </IsLogined>
    ),
  },
  {
    path: "/me",
    component: (
      <IsLogined>
        <Suspense fallback={<Loading isLoading />}>
          <MyPage />
        </Suspense>
      </IsLogined>
    ),
  },
  {
    path: "/onlineConsultation/:id",
    component: (
      <IsLogined>
        <OnlineConsultationPage />
      </IsLogined>
    ),
  },
  {
    path: "/onlineConsultationFinished/:id",
    component: (
      <IsLogined>
        <OnlineConsultationFinishedPage />
      </IsLogined>
    ),
  },
  {
    path: "/consultations",
    component: (
      <IsLogined>
        <ConsultationPage />
      </IsLogined>
    ),
  },
  {
    path: "/reConsultations/:consultationId",
    component: (
      <IsLogined>
        <ReConsultationsPage />
      </IsLogined>
    ),
  },
  {
    path: "/auth/callback",
    component: <AuthCallbackPage />,
  },
  {
    path: "/signUp/privacyPolicy",
    component: <PrivacyPolicyPage />,
  },
  {
    path: "/reEntryRoom/:id",
    component: (
      <IsLogined>
        <ReEntryRoomPage />
      </IsLogined>
    ),
  },
  {
    path: "/consultationsNew",
    component: (
      <IsLogined>
        <ConsultationNewPage />
      </IsLogined>
    ),
  },
];
export const Pages = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ textAlign: "center" }}
        />
        <Routes>
          {pages.map((item, index) => (
            <Route key={index} path={item.path} element={item.component} />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
